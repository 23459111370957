import 'react-toastify/dist/ReactToastify.css';

import { ReactNode, createContext, useContext, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import { arbetyApiUrl } from './constants';
import useAuth from './AuthContext';

type ProviderContextProps = {
	getAdminList: (
		page: number,
		approved: number,
		email: string,
		order: string,
		field: string,
		partner: string
	) => Promise<AxiosResponse>;
	getAdminWithdraws: (
		page: number,
		order: string,
		field: string,
		status: string,
		email: string
	) => Promise<AxiosResponse>;
	updatePartnerRevenueShare: (
		affiliateId: string,
		rev: number
	) => Promise<AxiosResponse>;
	updatePartnerCPA: (
		affiliateId: string,
		cpa: number
	) => Promise<AxiosResponse>;
	updatePartnerSub: (
		affiliateId: string,
		affiliate_to: string
	) => Promise<AxiosResponse>;
	approvePartner: (
		affiliateId: string,
		cpa: number,
		cpa_baseline: number,
		rev: number,
		sub: string
	) => Promise<AxiosResponse>;
	declinePartner: (affiliateId: string) => Promise<AxiosResponse>;
	AdminApproveOrReject: (
		id: string,
		type: number,
		reason?: string
	) => Promise<AxiosResponse>;
	partnerToUpdate: any;
	setPartnerToUpdate: any;
	// ========================
	resetPartnerPassword: (email: string) => Promise<AxiosResponse>;
	adminRevShare: (
		id: string,
		initial_date: string,
		end_date: string
	) => Promise<AxiosResponse>;
	adminCpa: (
		id: string,
		initial_date: string,
		end_date: string
	) => Promise<AxiosResponse>;
	balancePlayers: (
		id: string,
		initial_date: string,
		end_date: string
	) => Promise<AxiosResponse>;
	adminDeposits: (
		id: string,
		initial_date: string,
		end_date: string
	) => Promise<AxiosResponse>;
	updatePartnerRevShare: (
		id: string,
		revenue_share: string
	) => Promise<AxiosResponse>;
	updatePartnerCpa: (id: string, cpa: string) => Promise<AxiosResponse>;
	updatePartnerCpaBaseline: (
		id: string,
		cpa_baseline: number
	) => Promise<AxiosResponse>;
	activePartnerAdmin: (id: string) => Promise<AxiosResponse>;
	declinePartnerAdmin: (id: string) => Promise<AxiosResponse>;
	aproveContestation: (id: string, type: number) => Promise<AxiosResponse>;
	listContestation: () => Promise<AxiosResponse>;
	adminWallets: (id: string) => Promise<AxiosResponse>;
	adminAfilied: (id: string) => Promise<AxiosResponse>;
	listLinks: (id: string) => Promise<AxiosResponse>;
	getPayments: (
		initial_date: string,
		end_date: string,
		by_link: string,
		link: string,
		id: string
	) => Promise<AxiosResponse>;
};

const AdminContext = createContext({} as ProviderContextProps);

const afterDate = new Date();
afterDate.setMonth(afterDate.getMonth(), 1);
const beforeDate = new Date();
beforeDate.setMonth(beforeDate.getMonth() + 1, 0);

const dateRange = {
	initial_date: afterDate.toISOString().split('T')[0],
	end_date: beforeDate.toISOString().split('T')[0],
};

export const AdminProvider = ({ children }: { children: ReactNode }) => {
	const { accessToken } = useAuth();

	const [partnerToUpdate, setPartnerToUpdate] = useState({});

	const getAdminList = async (
		page: number,
		approved: number,
		email: string,
		order: string,
		field: string,
		partner: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/list`,
			{
				page,
				approved,
				email,
				order,
				field,
				partner,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const adminWallets = async (id: string) => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/wallets/partner`,
			{
				id,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	const adminAfilied = async (id: string) => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/subpartners/partner`,
			{
				id,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	const getAdminWithdraws = async (
		page: number,
		order: string,
		field: string,
		status: string,
		email: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/withdraws/get`,
			{
				page,
				order,
				field,
				status,
				email,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const updatePartnerRevenueShare = async (
		affiliateId: string,
		rev: number
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/revenue_share`,
			{
				id: affiliateId,
				revenue_share: Number(rev),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const updatePartnerCPA = async (
		affiliateId: string,
		cpa: number
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/cpa`,
			{
				id: affiliateId,
				cpa: Number(cpa),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const updatePartnerSub = async (
		affiliateId: string,
		affiliate_to: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/affiliate`,
			{
				id: affiliateId,
				affiliate_to: affiliate_to,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const approvePartner = async (
		affiliateId: string,
		cpa: number,
		cpa_baseline: number,
		rev: number,
		sub: string
	): Promise<AxiosResponse> => {
		// Verificando se os parâmetros opcionais não são nulos ou vazios antes de fazer as chamadas de atualização
		if (rev !== null && rev !== undefined) {
			await updatePartnerRevenueShare(affiliateId, rev);
		}
		if (cpa !== null && cpa !== undefined) {
			await updatePartnerCPA(affiliateId, cpa);
		}
		if (cpa_baseline !== null && cpa_baseline !== undefined) {
			await updatePartnerCpaBaseline(affiliateId, cpa_baseline);
		}
		if (sub !== null && sub !== undefined && sub !== '-' && sub !== '') {
			await updatePartnerSub(affiliateId, sub);
		}

		// Enviando a requisição com o ID do parceiro
		return axios.post(
			`${arbetyApiUrl}/partners/admin/approve`,
			{
				id: affiliateId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const declinePartner = async (
		affiliateId: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/decline`,
			{
				id: affiliateId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const AdminApproveOrReject = async (
		id: string,
		type: number,
		reason?: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/withdraws`,
			{
				id: id,
				type: type,
				reason: reason,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const resetPartnerPassword = async (
		email: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/recovery`,
			{
				email: email,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const adminRevShare = async (
		id: string,
		initial_date: string,
		end_date: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/revenueshare/partner`,
			{
				id: id,
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const adminCpa = async (
		id: string,
		initial_date: string,
		end_date: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/cpa/partner`,
			{
				id: id,
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const balancePlayers = async (
		id: string,
		initial_date: string,
		end_date: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/balance/partner`,
			{
				id: id,
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const adminDeposits = async (
		id: string,
		initial_date: string,
		end_date: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/deposits/partner`,
			{
				id: id,
				initial_date: initial_date,
				end_date: end_date,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const updatePartnerRevShare = async (
		id: string,
		revenue_share: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/revenue_share`,
			{
				id: id,
				revenue_share: Number(revenue_share),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const updatePartnerCpa = async (
		id: string,
		cpa: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/cpa`,
			{
				id: id,
				cpa: Number(cpa),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const updatePartnerCpaBaseline = async (
		id: string,
		cpa_baseline: number
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/cpa_baseline`,
			{
				id: id,
				cpa_baseline: Number(cpa_baseline),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const activePartnerAdmin = async (id: string): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/approve`,
			{
				id: id,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const declinePartnerAdmin = async (id: string): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/decline`,
			{
				id: id,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const listContestation = async (): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/contestation/list`,
			{
				id: '',
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const aproveContestation = async (
		id: string,
		type: number
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/contestation`,
			{
				id: id,
				type: Number(type),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const listLinks = async (id: string): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/links/list`,
			{
				id: id,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	const getPayments = async (
		initial_date: string,
		end_date: string,
		by_link: string,
		link: string,
		id: string
	): Promise<AxiosResponse> => {
		return axios.post(
			`${arbetyApiUrl}/partners/admin/payments`,
			{
				...(initial_date && end_date
					? { initial_date, end_date }
					: dateRange),
				...(typeof by_link === 'boolean' && by_link && link && id
					? {
							by_link: by_link,
							link: link,
							id,
					  }
					: {
							by_link: false,
							link,
							id,
					  }),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: accessToken,
				},
			}
		);
	};

	return (
		<AdminContext.Provider
			value={{
				getAdminList,
				partnerToUpdate,
				setPartnerToUpdate,
				updatePartnerCPA,
				updatePartnerSub,
				updatePartnerRevenueShare,
				approvePartner,
				declinePartner,
				getAdminWithdraws,
				AdminApproveOrReject,
				resetPartnerPassword,
				adminRevShare,
				adminCpa,
				adminDeposits,
				updatePartnerRevShare,
				updatePartnerCpa,
				updatePartnerCpaBaseline,
				activePartnerAdmin,
				declinePartnerAdmin,
				listContestation,
				aproveContestation,
				adminWallets,
				adminAfilied,
				balancePlayers,
				listLinks,
				getPayments,
			}}
		>
			{children}
		</AdminContext.Provider>
	);
};

export const useAdmin = () => {
	const context = useContext(AdminContext);
	if (!context) throw new Error('Provider inserido de maneira errada');
	return context;
};

export default useAdmin;
