import './style.css';

import logoArbety from 'src/assets/logo.png';

const LoadingComponent = () => {
	return (
		<div className="container-loading">
			<img src={logoArbety} alt="" />
		</div>
	);
};

export default LoadingComponent;

export const LoadingComponentLogin = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '12px',
				alignItems: 'center',
				alignContent: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
			}}
			className="loading-component loading-login"
		>
			<div className="circle" />
		</div>
	);
};
