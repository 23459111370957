import './assets/icons/remixicon.css';
import './assets/less/yoda-theme.less';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 30,
		},
	},
});

const root = createRoot(document.getElementById('root')!);
root.render(
	<QueryClientProvider client={queryClient}>
		<Suspense fallback="loading">
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Suspense>
	</QueryClientProvider>
);
