import enLang from './entries/en_US';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import trLang from './entries/tr_TR';

const AppLocale = {
	en: enLang,
	fr: frLang,
	tr: trLang,
	es: esLang,
};

export default AppLocale;
