import {
	Clock,
	LinkCircle,
	MoneyRecive,
	PresentionChart,
	Profile2User,
	Setting3,
	TrendUp,
	WalletAdd,
} from 'iconsax-react';

const navigation = [
	{
		header: 'DASHBOARD',
		roles: ['affiliated'],
	},
	{
		id: 'dashboards-analytics',
		title: 'Análises',
		icon: <PresentionChart size={18} />,
		icon2: <PresentionChart variant="Bold" size={18} />,
		navLink: '/analises',
		roles: ['affiliated'],
	},
	{
		id: 'contestations',
		title: 'Solicitações Pendentes',
		icon: <Clock size={18} />,
		icon2: <Clock variant="Bold" size={18} />,
		navLink: '/contestations',
		roles: ['affiliated'],
	},
	{
		id: 'dashboards-home',
		title: 'Afiliados',
		icon: <Profile2User size={18} />,
		icon2: <Profile2User variant="Bold" size={18} />,
		navLink: '/home',
		roles: ['admin'],
	},
	{
		header: 'MEUS LINKS',
		roles: ['affiliated'],
	},
	{
		id: 'apps-calendar',
		title: 'Links personalizados',
		icon: <LinkCircle size={18} />,
		icon2: <LinkCircle variant="Bold" size={18} />,
		navLink: '/links-personalizados',
		roles: ['affiliated'],
	},
	{
		header: 'ESTATÍSTICAS',
		roles: ['affiliated'],
	},
	{
		id: 'dashboards-ecommerce',
		title: 'Desempenho',
		icon: <TrendUp size={18} />,
		icon2: <TrendUp variant="Bold" size={18} />,
		navLink: '/desempenho',
		roles: ['affiliated'],
	},
	{
		header: 'SUB-AFILIADOS',
		roles: ['affiliated'],
	},
	{
		id: 'user-interface-buttons',
		title: 'Gerenciamento',
		icon: <Setting3 size={18} />,
		icon2: <Setting3 variant="Bold" size={18} />,
		navLink: '/gerenciamento',
		roles: ['affiliated'],
	},
	{
		header: 'CARTEIRA',
		roles: ['affiliated'],
	},
	{
		id: 'charts',
		title: 'Gestão de Ganhos',
		icon: <MoneyRecive size={18} />,
		icon2: <MoneyRecive variant="Bold" size={18} />,
		navLink: '/carteira',
		roles: ['affiliated'],
	},
	{
		id: 'charts',
		title: 'Carteiras Cadastradas',
		icon: <WalletAdd size={18} />,
		icon2: <WalletAdd variant="Bold" size={18} />,
		navLink: '/carteiras-cadastradas',
		roles: ['affiliated'],
	},
	// {
	// 	header: 'GAMIFICAÇÃO',
	// 	roles: ['affiliated'],
	// },
	// {
	// 	id: 'user-interface-icons',
	// 	title: 'Níveis de afiliado',
	// 	icon: <Rank size={18} />,
	// 	icon2: <Rank variant="Bold" size={18} />,
	// 	navLink: '/',
	// 	roles: ['affiliated'],
	// },

	{
		header: 'SOLICITAÇÕES DE CADASTRO',
		roles: ['admin'],
	},
	{
		id: 'charts',
		title: 'Aprovar Cadastro',
		icon: <LinkCircle size={18} />,
		icon2: <LinkCircle variant="Bold" size={18} />,
		navLink: '/cadastro',
		roles: ['admin'],
	},
	{
		header: 'SOLICITAÇÕES DE SAQUE',
		roles: ['admin'],
	},
	{
		id: 'charts',
		title: 'Aprovar Saques',
		icon: <MoneyRecive size={18} />,
		icon2: <MoneyRecive variant="Bold" size={18} />,
		navLink: '/saques',
		roles: ['admin'],
	},
	{
		header: 'Contestações',
		roles: ['admin'],
	},
	{
		id: 'charts',
		title: 'Solicitações Pendentes',
		icon: <Clock size={18} />,
		icon2: <Clock variant="Bold" size={18} />,
		navLink: '/contestation-admin',
		roles: ['admin'],
	},
];

export default navigation;
