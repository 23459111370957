import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { arbetyApiUrl } from './constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface UserData {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	password: string;
	rev_share: number;
	cpa: number;
	phone_number: string;
	document: string;
	affiliated_at: string;
	created_at: string;
	approved: number;
	origin: null;
	is_admin: number;
	cpa_baseline: number;
	balance: number;
	balance_cpa: number;
	balance_revenue_share: number;
}

type AuthContextProps = {
	initialized: boolean;
	accessToken: null | string;
	userData: null | UserData;
	handleLogin: (email: string, password: string) => void;
	getUserData: (token: string) => void;
	createAccount: (accountData: {
		email: string;
		password: string;
		first_name: string;
		last_name: string;
		cpf: string;
		phone_number: string;
		origin: string;
		affiliated_at?: string;
	}) => void;
	recoveryPassword: (email: string) => void;
	updatePassword: (code: string, password: string) => void;
	verifyPass: (roles: string[]) => { canPass: boolean; userRoles: string[] };
	logout: () => void;
	changePassword: (
		oldPassword: string,
		confirmPassword: string,
		newPassword: string
	) => void;
	loadingLogin: any;
	setLoadingLogin: any;
};

const AuthContext = createContext({} as AuthContextProps);

const routeRoleRule: {
	[key: string]: {
		key: keyof UserData;
		value: any[];
	}[];
} = {
	admin: [
		{
			key: 'is_admin',
			value: [1],
		},
	],
	affiliated: [
		{
			key: 'approved',
			value: [1],
		},
		{
			key: 'is_admin',
			value: [0, null],
		},
	],
	guest: [],
};

export const defaultRoleRoute: { [key: keyof typeof routeRoleRule]: string } = {
	admin: '/home',
	affiliated: '/analises',
	guest: '/login',
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [state, setState] = useState<{
		initialized: boolean;
		accessToken: null | string;
	}>({
		initialized: false,
		accessToken: null,
	});
	const [userData, setUserData] = useState<UserData | null>(null);
	const navigate = useNavigate();

	const handleLogin = async (email: string, password: string) => {
		return axios
			.post(
				`${arbetyApiUrl}/partners/signin`,
				{
					email,
					password,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then((response) => {
				const accessToken: string | null = response.data.token;
				if (!accessToken) return;

				getUserData(accessToken);
			})
			.catch(() => {
				toast.error(
					'😞 Ops! Erro ao fazer login. Confira seus dados.',
					{
						position: 'bottom-right',
						autoClose: 4000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					}
				);

				return false;
			});
	};

	const getUserData = useCallback(
		(token: string): void => {
			axios
				.get(`${arbetyApiUrl}/partners/user`, {
					headers: {
						'Content-Type': 'application/json',
						authorization: token,
					},
				})
				.then((response) => {
					setState((_state) => ({
						initialized: true,
						accessToken: token,
					}));
					sessionStorage.setItem('access_token_partners', token);
					if (!response.data.data.balance_cpa)
						response.data.data.balance_cpa = 0;
					if (!response.data.data.balance_revenue_share)
						response.data.data.balance_revenue_share = 0;
					setUserData(response.data.data);
				})
				.catch((error) => {
					console.error(
						`[AuthContext] Error on getUserData: ${error}`
					);
					sessionStorage.removeItem('access_token_partners');
					navigate('/await');
				});
		},
		[navigate]
	);

	const [loadingLogin, setLoadingLogin] = useState(false);

	const createAccount = (accountData: {
		email: string;
		password: string;
		first_name: string;
		last_name: string;
		cpf: string;
		phone_number: string;
		origin: string;
		affiliated_at?: string;
	}) => {
		accountData.cpf = accountData.cpf.replace(/[^0-9.]/g, '');

		if (!accountData.origin) {
			accountData.origin = 'unknown';
		}

		axios
			.post(`${arbetyApiUrl}/partners/signup`, accountData, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(() => {
				navigate('/await');
			})
			.catch((error) => {
				console.error(`[AuthContext] Error on getUserData: ${error}`);

				if (error.response && error.response.status === 409) {
					toast.error(
						'Já existe uma conta com este email. Por favor, tente novamente com um email diferente.'
					);
				} else {
					toast.error(
						'😞 Ops! Erro ao criar conta. Confira seus dados.',
						{
							position: 'bottom-right',
							autoClose: 4000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'light',
						}
					);
				}
				setLoadingLogin(false);
			});
	};

	const recoveryPassword = (email: string) => {
		axios
			.post(
				`${arbetyApiUrl}/partners/recovery`,
				{
					email,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then(() => {
				toast.success(
					'Código enviado com sucesso, verifique seu email',
					{
						position: 'bottom-right',
						autoClose: 1900,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					}
				);
				navigate('/reset-password');
			})
			.catch((error) => {
				console.error(
					`[AuthContext] Error on recoveryPassword: ${error}`
				);

				toast.error('Erro ao recuperar a senha!');
			});
	};

	const updatePassword = (code: string, password: string): void => {
		axios
			.post(
				`${arbetyApiUrl}/partners/update-password`,
				{
					code,
					password,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
			.then(() => {
				toast.success('Codigo enviado para seu email!', {
					position: 'bottom-right',
					autoClose: 1900,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});

				navigate('/reset-password-success');
			})
			.catch((error) => {
				console.error(
					`[AuthContext] Error on updatePassword: ${error}`
				);
				toast.error('Erro ao recuperar a senha!');
			});
	};

	const verifyPass = (
		roles: string[]
	): { canPass: boolean; userRoles: string[] } => {
		let canPass = false;
		let userRoles: string[] = [];

		if (roles.length > 0) {
			if (userData) {
				for (const role of Object.keys(routeRoleRule)) {
					const rules = routeRoleRule[role];
					let passAllRules = false;

					if (rules.length > 0) {
						for (const rule of rules) {
							passAllRules =
								rule.key &&
								rule.value.some(
									(value) => userData[rule.key] === value
								);
						}
					} else {
						passAllRules = true;
					}

					if (passAllRules) {
						userRoles.push(role);
					}
				}
			}
		}

		canPass = roles.every((role) => userRoles.includes(role));

		return { canPass, userRoles };
	};

	const logout = () => {
		setState((_state) => ({ ..._state, accessToken: null }));
		setUserData(null);
		sessionStorage.removeItem('access_token_partners');
	};

	const changePassword = (
		oldPassword: string,
		confirmPassword: string,
		newPassword: string
	) => {
		if (newPassword !== confirmPassword) {
			toast.error('A nova senha e a confirmação de senha não coincidem.');
			return;
		}

		axios
			.put(
				`${arbetyApiUrl}/partners/user`,
				{
					password: newPassword,
					old_password: oldPassword,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${state.accessToken}`,
					},
				}
			)
			.then(() => {
				toast.success('Senha alterada com sucesso!');
				navigate('/perfil');
			})
			.catch((error) => {
				console.error(
					`[AuthContext] Error on changePassword: ${error}`
				);
				if (error.response && error.response.status === 401) {
					toast.error(
						'Senha antiga incorreta. Verifique sua senha e tente novamente.'
					);
				} else {
					toast.error(
						'Erro ao alterar a senha. Por favor, tente novamente mais tarde.'
					);
				}
			});
	};

	useEffect(() => {
		const accessToken = sessionStorage.getItem('access_token_partners');

		if (accessToken && !userData) {
			getUserData(accessToken);
		} else if (!state.initialized) {
			setState((_state) => ({ ..._state, initialized: true }));
		}
	}, [userData]);

	return (
		<AuthContext.Provider
			value={{
				...state,
				userData,
				handleLogin,
				getUserData,
				createAccount,
				recoveryPassword,
				updatePassword,
				verifyPass,
				logout,
				changePassword,
				setLoadingLogin,
				loadingLogin,
			}}
		>
			{children}
			<ToastContainer />
		</AuthContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) throw new Error('Provider inserido de maneira errada');
	return context;
};

export default useAuth;
