import { Col, Row } from 'antd';

export default function Footer() {
	return (
		<Col span={24} className="hp-py-32">
			<p className="hp-mb-8 hp-p1-body hp-text-color-black-60">
				© 2023 Eurowin | Todos os direitos reservados.
			</p>

			<Row align="middle" justify="center" gutter={16}>
				<Col>
					<span className="hp-p1-body hp-text-color-black-80 hp-hover-text-color-primary-1">
						Privacy Policy
					</span>
				</Col>

				<Col>
					<span className="hp-p1-body hp-text-color-black-80 hp-hover-text-color-primary-1">
						Term of use
					</span>
				</Col>
			</Row>
		</Col>
	);
}
