import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Layout, Button, Row, Col, Dropdown, Space, Menu } from 'antd';
import { motion } from 'framer-motion';
import { RiMenuFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
// import HeaderNotifications from './HeaderNotifications';
import HeaderSearch from './HeaderSearch';
import HeaderText from './HeaderText';
import HeaderUser from './HeaderUser';
import useAuth from 'src/contexts/AuthContext';
import { EmptyWallet } from 'iconsax-react';
import './header.css';
import useData from 'src/contexts/DataContext';
const { Header } = Layout;

const afterDate = new Date();
afterDate.setMonth(afterDate.getMonth(), 1);
const beforeDate = new Date();
beforeDate.setMonth(beforeDate.getMonth() + 1, 0);

const enableSearchPaths = ['/analises'];

export default function MenuHeader(props: {
	setVisible: Dispatch<SetStateAction<boolean>>;
}) {
	const { setVisible } = props;
	const { pathname } = useLocation();
	const { openModalSaque } = useData();

	const [searchHeader, setSearchHeader] = useState(false);
	const [searchActive, setSearchActive] = useState(false);

	const inputFocusRef = useRef(null);
	const inputFocusProp = {
		ref: inputFocusRef,
	};

	const { userData } = useAuth();
	const cpa = userData?.balance_cpa;
	const revShare = userData?.balance_revenue_share;

	let somaFormatada: string = '';

	if (cpa !== undefined && revShare !== undefined) {
		const soma: number = cpa + revShare;

		somaFormatada = soma.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
	}

	setTimeout(() => setSearchActive(searchHeader), 100);

	const showDrawer = () => {
		setVisible(true);
		setSearchHeader(false);
	};

	const isSearchPath = enableSearchPaths.includes(decodeURI(pathname));
	const setup: any = (
		<Menu>
			<Menu.Item className="wallet_opt" key="1">
				Resumo da Carteira
			</Menu.Item>
			{/* <Menu.Item className="wallet_opt" key="2">
				<div className="wallet_content">
					<p>Saldo Disponível:</p>
					<b>R$ 44.790,00</b>
				</div>
			</Menu.Item> */}
			<Menu.Item className="wallet_opt" key="2">
				<div className="wallet_content">
					<p>CPA:</p>
					<b>
						{cpa !== undefined
							? cpa.toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL',
							  })
							: '-'}
					</b>
				</div>
			</Menu.Item>
			<Menu.Item className="wallet_opt" key="3">
				<div className="wallet_content">
					<p>Revenue Share:</p>
					<b>
						{revShare !== undefined
							? revShare.toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL',
							  })
							: '-'}
					</b>
				</div>
			</Menu.Item>
			<Menu.Item className="w-full" key="4">
				<Button
					type="primary"
					onClick={() => openModalSaque()}
					className="w-full hp-bg-green-gradient"
				>
					Efetuar saque
				</Button>
			</Menu.Item>
		</Menu>
	);

	const headerChildren = () => {
		return (
			<Row
				align="middle"
				justify="space-between"
				className={isSearchPath ? 'teste' : ''}
			>
				<Col
					style={{ display: !searchHeader ? 'none' : 'block' }}
					className={`hp-mr-md-0 hp-mr-16 hp-pr-0 hp-header-search ${
						searchActive && 'hp-header-search-active'
					}`}
				>
					<HeaderSearch
						inputFocusProp={inputFocusProp}
						setSearchHeader={setSearchHeader}
					/>
				</Col>

				{!searchHeader && <HeaderText />}
				<div className="flex-header">
					<Button
						ghost
						className="hp-mobile-sidebar-button hp-border-none"
						onClick={showDrawer}
						icon={
							<RiMenuFill
								size={24}
								className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
							/>
						}
					/>

					<Col className="margin-header">
						<Row className="row-header">
							<Dropdown
								className="wallet_dropdown"
								overlay={setup}
								placement="bottomRight"
							>
								<Space className="ixiporra">
									{userData?.is_admin === 1 ? null : (
										<div className="saldo-header">
											<svg
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10.75 12.5203H10.98C11.31 12.5203 11.59 12.2203 11.59 11.8503C11.59 11.3803 11.48 11.3203 11.16 11.2103L10.75 11.0703V12.5203Z"
													fill="#ECE417"
												/>
												<path
													d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.57999 14.42C5.86999 14.71 5.86999 15.19 5.57999 15.48C5.42999 15.63 5.23999 15.7 5.04999 15.7C4.85999 15.7 4.66999 15.63 4.51999 15.48C3.05999 14.02 2.25 12.07 2.25 10C2.25 7.93 3.05999 5.98 4.51999 4.52C4.80999 4.23 5.28999 4.23 5.57999 4.52C5.86999 4.81 5.86999 5.29 5.57999 5.58C4.39999 6.76 3.75 8.33 3.75 10C3.75 11.67 4.39999 13.24 5.57999 14.42ZM11.65 9.78C12.61 10.12 13.08 10.79 13.08 11.84C13.08 13.04 12.14 14.01 10.97 14.01H10.74V14.19C10.74 14.6 10.4 14.94 9.99 14.94C9.58 14.94 9.24 14.6 9.24 14.19V14.01H9.21C7.93999 14.01 6.89999 12.94 6.89999 11.63C6.89999 11.22 7.23999 10.88 7.64999 10.88C8.06 10.88 8.4 11.22 8.4 11.63C8.4 12.12 8.76 12.51 9.21 12.51H9.24V10.53L8.34 10.21C7.38 9.87 6.91 9.2 6.91 8.15C6.91 6.95 7.84999 5.98 9.02 5.98H9.25V5.8C9.25 5.39 9.59 5.05 10 5.05C10.41 5.05 10.75 5.39 10.75 5.8V5.98H10.78C12.05 5.98 13.09 7.05 13.09 8.36C13.09 8.77 12.75 9.11 12.34 9.11C11.93 9.11 11.59 8.77 11.59 8.36C11.59 7.87 11.23 7.48 10.78 7.48H10.75V9.46L11.65 9.78ZM15.48 15.48C15.33 15.63 15.14 15.7 14.95 15.7C14.76 15.7 14.57 15.63 14.42 15.48C14.13 15.19 14.13 14.71 14.42 14.42C15.6 13.24 16.25 11.67 16.25 10C16.25 8.33 15.6 6.76 14.42 5.58C14.13 5.29 14.13 4.81 14.42 4.52C14.71 4.23 15.19 4.23 15.48 4.52C16.94 5.98 17.75 7.93 17.75 10C17.75 12.07 16.94 14.02 15.48 15.48Z"
													fill="#ECE417"
												/>
												<path
													d="M8.41992 8.1605C8.41992 8.6305 8.52992 8.6905 8.84992 8.8005L9.25992 8.9405V7.48047H9.02992C8.68992 7.48047 8.41992 7.79047 8.41992 8.1605Z"
													fill="#ECE417"
												/>
											</svg>
											{somaFormatada}
											<div className="shadow-green-header">
												<EmptyWallet
													size="20"
													color="#fff"
												/>
											</div>
										</div>
									)}
								</Space>
							</Dropdown>
							{/* <HeaderNotifications /> */}
							<HeaderUser />
						</Row>
					</Col>
				</div>
			</Row>
		);
	};

	return (
		<Header>
			<Row justify="center" className="hp-w-100">
				<Col span={24}>
					<motion.div
						initial={{ opacity: 0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{
							type: 'spring',
							duration: 0.5,
							delay: 0.1,
						}}
						className="hp-w-100"
					>
						{headerChildren()}
					</motion.div>
				</Col>
			</Row>
		</Header>
	);
}
