import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useState,
} from 'react';
import axios, { AxiosResponse } from 'axios';

import { PartnerLink } from 'src/@types/api';
import { arbetyApiUrl } from './constants';
import { toast } from 'react-toastify';
import useAuth from './AuthContext';

type WalletData = {
	id: string;
	name: string;
	type: number;
	created_at: string;
	userId: string;
	wallet_key: string;
};

type DataContextState = {
	initialized: boolean;
	links: PartnerLink[];
	walletData: WalletData[];
};

type DataContextProps = DataContextState & {
	setState: Dispatch<SetStateAction<DataContextState>>;
	createPartnerWallet: (
		name: string,
		type: number,
		key: string
	) => Promise<AxiosResponse>;
	deletePartnerWallet: (id: string) => Promise<AxiosResponse>;

	updatePhone: (phone_number: string) => Promise<
		AxiosResponse<{
			code: number;
			message: string;
		}>
	>;

	createPartnerLink: (
		name: any,
		min_deposit: any
	) => Promise<
		AxiosResponse<{
			code: number;
			message: string;
		}>
	>;
	solicitarPagamento: (walletId: any, valueSaldo: any) => void;
	handleContestion: (emails: string) => Promise<AxiosResponse>;
	getContestation: () => Promise<
		AxiosResponse<{
			contestations: {
				email: string;
				status: string;
				created_at: number;
			}[];
		}>
	>;
	getLoginActivities: () => Promise<any>;
	fetchPartnerWallet: () => Promise<any>;
	closeModalSaque: any;
	visibleModalSaque: any;
	openModalSaque: any;
};

const DataContext = createContext({} as DataContextProps);

export const DataProvider = ({ children }: { children: ReactNode }) => {
	const [state, setState] = useState<DataContextState>({
		initialized: false,
		links: [],
		walletData: [],
	});
	const { accessToken } = useAuth();
	const [visibleModalSaque, setVisibleModalSaque] = useState(false);

	const openModalSaque = () => {
		setVisibleModalSaque(true);
	};
	const closeModalSaque = () => {
		setVisibleModalSaque(false);
	};

	const createPartnerLink = (name: any, min_deposit: any) => {
		name = name.trim().replace(/ /g, '');
		return axios.post(
			`${arbetyApiUrl}/partners/links`,
			{
				name,
				min_deposit: Number(min_deposit),
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	const solicitarPagamento = async (valueSaldo: any, walletId: any) => {
		try {
			const response = await axios.post(
				`${arbetyApiUrl}/partners/withdraw`,
				{
					amount: Number(valueSaldo),
					wallet: walletId,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						authorization: accessToken,
					},
				}
			);
			return response;
		} catch (error: any) {
			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				const errorMessage = error.response.data.message;

				switch (errorMessage) {
					case 'Max amount to withdraw is R$50000':
						toast.error(
							'😞 Ops! O valor máximo para saque é de R$ 50.000',
							{}
						);
						break;
					case 'Insuficient balance':
						toast.error('😞 Ops! Saldo insuficiente', {});
						break;
					case 'Min balance to withdraw is R$200':
						toast.error(
							'😞 Ops! Saldo mínimo para saque é de R$ 200',
							{}
						);
						break;
					case 'Withdraw already in progress in week':
						toast.error(
							'😞 Ops! Retirada já em andamento na semana',
							{}
						);
						break;
					case 'Withdraw already in progress':
						toast.error('😞 Ops! Retirada já em andamento', {});
						break;
					case 'Min balance to withdraw is R$200.00':
						toast.error(
							'Saldo mínimo para saque é de R$ 200,00',
							{}
						);
						break;
					default:
						toast.error(
							'😞 Ops! Ocorreu um erro durante a solicitação do saque.',
							{}
						);
						break;
				}
			} else {
				toast.error(
					'😞 Ops! Ocorreu um erro durante a solicitação do saque. ',
					{}
				);
			}
			throw error;
		}
	};

	const fetchPartnerWallet = async () => {
		try {
			const response = await axios.get(
				`${arbetyApiUrl}/partners/wallets`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: accessToken,
					},
				}
			);

			return response.data.links;
		} catch (error) {
			throw error;
		}
	};

	const createPartnerWallet = async (
		name: string,
		type: number,
		key: string
	) => {
		return axios.post(
			`${arbetyApiUrl}/partners/wallets`,
			{
				name,
				type,
				key,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	const updatePhone = async (phone_number: string) => {
		return axios.post(
			`${arbetyApiUrl}/partners/user`,
			{
				phone_number,
				min_deposit: 25,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	const deletePartnerWallet = async (id: string) => {
		return axios.delete(`${arbetyApiUrl}/partners/wallets`, {
			data: {
				id,
			},
			headers: {
				'Content-Type': 'application/json',
				authorization: accessToken,
			},
		});
	};

	const handleContestion = async (emails: string) => {
		return axios.post(
			`${arbetyApiUrl}/partners/contestation`,
			{
				emails,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: accessToken,
				},
			}
		);
	};

	const getContestation = async () => {
		return axios.get(`${arbetyApiUrl}/partners/contestation`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: accessToken,
			},
		});
	};

	const getLoginActivities = async () => {
		return axios.get(`${arbetyApiUrl}/partners/activities`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: accessToken,
			},
		});
	};

	return (
		<DataContext.Provider
			value={{
				...state,
				setState,
				createPartnerLink,
				fetchPartnerWallet,
				createPartnerWallet,
				deletePartnerWallet,
				solicitarPagamento,
				handleContestion,
				getContestation,
				getLoginActivities,
				updatePhone,
				closeModalSaque,
				visibleModalSaque,
				openModalSaque,
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

export const useData = () => {
	const context = useContext(DataContext);
	if (!context) throw new Error('Provider inserido de maneira errada');
	return context;
};

export default useData;
