import './header.css';

import { Button, Col, Divider, Dropdown } from 'antd';
import { LogoutCurve, MessageQuestion, UserSquare } from 'iconsax-react';

import { Link } from 'react-router-dom';
import avatarImg from 'src/assets/images/memoji/memoji-3.png';
import useAuth from 'src/contexts/AuthContext';

export default function HeaderUser() {
	const { logout } = useAuth();

	const handleLogout = () => {
		logout();
	};

	const menu = (
		<div className="hp-user-dropdown dropdown_content hp-border-radius hp-border-color-dark-80 hp-pt-6 hp-px-18 hp-mt-16">
			<div className="dropdown_title">Perfil</div>

			<Link to="/profile" className="dropdown_opt">
				<UserSquare size="20" color="#4E4E4E" />
				Ver perfil
			</Link>

			<Link
				to="https://api.whatsapp.com/send?phone=5511915583604&text=Ol%C3%A1%2C+gostaria+de+saber+mais+sobre+afilia%C3%A7%C3%A3o+na+Eurowin"
				target="_blank"
				className="dropdown_opt"
			>
				<MessageQuestion size="20" color="#4E4E4E" />
				Falar com suporte
			</Link>

			<Divider className="hp-mb-4 hp-mt-0" />

			<Button
				onClick={(e) => {
					e.preventDefault();
					handleLogout();
				}}
				className="dropdown_opt"
			>
				<LogoutCurve size="20" color="#4E4E4E" />
				Sair da conta
			</Button>
		</div>
	);

	return (
		<Col>
			<Dropdown overlay={menu} placement="bottomLeft">
				<div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
					<div className="dropdown_avatar hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex">
						<img src={avatarImg} alt="User" height="100%" />
					</div>
				</div>
			</Dropdown>
		</Col>
	);
}
