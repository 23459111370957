import { createContext, useContext, useState, ReactNode } from 'react';

type DadosContextProps = {
	revenueShareCard: any;
	setRevenueShareCard: (value: any) => void;
	cpaCard: any;
	setCpaCard: (value: any) => void;
	balanceCard: any;
	setBalanceCard: (value: any) => void;
	depositsCard: any;
	setDepositsCard: (value: any) => void;
	firstDepositCard: any;
	setFirstDepositCard: (value: any) => void;
	topLinks: any;
	setTopLinks: (value: any) => void;
	registers: any;
	setRegisters: (value: any) => void;
	partnes: Array<any>;
	setPartnes: (value: Array<any>) => void;
	revSharePerformance: any;
	setRevSharePerformance: any;
	revSharePerformanceLoading: any;
	setRevSharePerformanceLoading: any;
	cpaPerformance: any;
	setCpaPerformance: any;
	cpaPerformanceLoading: any;
	setCpaPerformanceLoading: any;
	depositsPerformance: any;
	setDepositsPerformance: any;
	depositsLoading: any;
	setDepositsLoading: any;
	redepositsPerformance: any;
	setRedepositsPerformance: any;
	redepositsPerformanceLoading: any;
	setRedepositsPerformanceLoading: any;
	fistPerformance: any;
	setFistPerformance: any;
	fistPerformanceLoading: any;
	setFistPerformanceLoading: any;
	withdrawsformance: any;
	setWithdrawsformance: any;
	withdrawsformanceLoading: any;
	setWithdrawsformanceLoading: any;
	totalDeposited: any;
	setTotalDeposited: any;
	totalDepositedLoading: any;
	setTotalDepositedLoading: any;
	registersformance: any;
	setRegistersformance: any;
	registersformanceLoading: any;
	setRegistersformanceLoading: any;
	usersformance: any;
	setUsersformance: any;
	loadingTable: any;
	setLoadingTable: any;
	subPartnersData: any;
	setSubPartnersData: any;
	cpa: any;
	setCpa: any;
	revShare: any;
	setRevShare: any;
	setTablePayments: any;
	tablePayments: any;
	revShareValue: any;
	setRevShareValue: any;
	cpaValue: any;
	setCpaValue: any;
	depositsValue: any;
	setDepositsValue: any;
	setPage: any;
	page: any;
};
const DadosContext = createContext({} as DadosContextProps);

export const DadosProvider = ({ children }: { children: ReactNode }) => {
	// ESTADOS DA TELA DE ANALISES
	const [revenueShareCard, setRevenueShareCard] = useState<any>(null);
	const [cpaCard, setCpaCard] = useState<any>(null);
	const [balanceCard, setBalanceCard] = useState<any>(null);
	const [depositsCard, setDepositsCard] = useState<any>(null);
	const [firstDepositCard, setFirstDepositCard] = useState<any>(null);

	const tableLocalLinks = new Array(2).fill({
		id: '',
		name: '',
		registers: 0,
		userId: '',
		created_at: '',
	});

	const [topLinks, setTopLinks] = useState<
		{
			id: string;
			name: string;
			registers: number;
			userId: string;
			created_at: string;
		}[]
	>(tableLocalLinks);

	const tableLocal = new Array(10).fill({
		id: '1',
		balance: 0,
		bonusBalance: 0,
		partner_link: '',
		revenue_share: 0,
		cpa: 0,
		deposits: 0,
		deposits_quantity: 0,
		withdraws: 0,
	});

	const [registers, setRegisters] = useState<
		{
			id: string;
			balance: number;
			bonusBalance: number;
			partner_link: string;
			revenue_share: number;
			cpa: number;
			deposits: number;
			deposits_quantity: number;
			withdraws: number;
		}[]
	>(tableLocal);

	// ESTADOS DA TELA DE AFILIADOS

	const [partnes, setPartnes] = useState<any>([]);

	const qualquerCoisa = new Array(10).fill({
		id: '',
		partner_link: '',
		created: '',
	});

	// ESTADOS DA TELA DE DESEMPENHO

	const [revSharePerformance, setRevSharePerformance] = useState(0);
	const [revSharePerformanceLoading, setRevSharePerformanceLoading] =
		useState<true | false>(true);

	const [cpaPerformance, setCpaPerformance] = useState(0);
	const [cpaPerformanceLoading, setCpaPerformanceLoading] = useState<
		true | false
	>(true);

	const [depositsPerformance, setDepositsPerformance] = useState(0);
	const [depositsLoading, setDepositsLoading] = useState<true | false>(true);

	const [redepositsPerformance, setRedepositsPerformance] = useState(0);
	const [redepositsPerformanceLoading, setRedepositsPerformanceLoading] =
		useState(true);

	const [fistPerformance, setFistPerformance] = useState(0);
	const [fistPerformanceLoading, setFistPerformanceLoading] = useState(true);

	const [withdrawsformance, setWithdrawsformance] = useState(0);
	const [withdrawsformanceLoading, setWithdrawsformanceLoading] =
		useState(true);

	const [totalDeposited, setTotalDeposited] = useState(0);
	const [totalDepositedLoading, setTotalDepositedLoading] = useState(true);

	const [page, setPage] = useState(0);

	const [registersformance, setRegistersformance] = useState(0);
	const [registersformanceLoading, setRegistersformanceLoading] =
		useState(true);
	const [usersformance, setUsersformance] = useState([qualquerCoisa]);

	const [loadingTable, setLoadingTable] = useState<true | false>(true);

	const [subPartnersData, setSubPartnersData] = useState<any>([]);

	const [cpa, setCpa] = useState(0);
	const [revShare, setRevShare] = useState(0);

	const [tablePayments, setTablePayments] = useState<any>([]);

	// ESTADOS DA TELA DE ADMIN PERFIL

	const [revShareValue, setRevShareValue] = useState(0);
	const [cpaValue, setCpaValue] = useState(0);
	const [depositsValue, setDepositsValue] = useState(0);

	return (
		<DadosContext.Provider
			value={{
				revShareValue,
				setRevShareValue,
				cpaValue,
				setCpaValue,
				depositsValue,
				setDepositsValue,
				cpa,
				setCpa,
				revShare,
				setRevShare,
				setTablePayments,
				subPartnersData,
				tablePayments,
				setSubPartnersData,
				revenueShareCard,
				setRevenueShareCard,
				cpaCard,
				setCpaCard,
				balanceCard,
				setBalanceCard,
				depositsCard,
				setDepositsCard,
				firstDepositCard,
				setFirstDepositCard,
				topLinks,
				setTopLinks,
				registers,
				setRegisters,
				partnes,
				setPartnes,
				revSharePerformance,
				setRevSharePerformance,
				revSharePerformanceLoading,
				setRevSharePerformanceLoading,
				cpaPerformance,
				setCpaPerformance,
				cpaPerformanceLoading,
				setCpaPerformanceLoading,
				depositsPerformance,
				setDepositsPerformance,
				depositsLoading,
				setDepositsLoading,
				redepositsPerformance,
				setRedepositsPerformance,
				redepositsPerformanceLoading,
				setRedepositsPerformanceLoading,
				fistPerformance,
				setFistPerformance,
				fistPerformanceLoading,
				setFistPerformanceLoading,
				withdrawsformance,
				setWithdrawsformance,
				withdrawsformanceLoading,
				setWithdrawsformanceLoading,
				totalDeposited,
				setTotalDeposited,
				totalDepositedLoading,
				setTotalDepositedLoading,
				registersformance,
				setRegistersformance,
				registersformanceLoading,
				setRegistersformanceLoading,
				usersformance,
				setUsersformance,
				loadingTable,
				setLoadingTable,
				setPage,
				page,
			}}
		>
			{children}
		</DadosContext.Provider>
	);
};

export const useDados = () => {
	const Context = useContext(DadosContext);
	if (!Context)
		throw new Error(
			'useDados deve ser usado dentro de um DadosProvider para funcionar corretamente.'
		);
	return Context;
};

export default useDados;
