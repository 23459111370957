import useAuth, { defaultRoleRoute } from 'src/contexts/AuthContext';

import { Navigate } from 'react-router-dom';

export default function RoleGuard({
	children,
	roles,
}: {
	children: JSX.Element;
	roles: string[];
}): JSX.Element {
	const { userData, initialized, verifyPass } = useAuth();

	if (!initialized) return <></>;
	let { canPass, userRoles } = verifyPass(roles);

	if (roles.find((role) => role === 'guest')) {
		if (!userData) {
			canPass = true;
		} else {
			return <Navigate to={defaultRoleRoute[userRoles[0]]} />;
		}
	}

	if (!canPass) return <Navigate to="/login" />;

	return children;
}
