import { lazy } from 'react';

export const PagesRoutes = [
	/**
	 * Admin
	 */
	{
		path: '/home',
		component: lazy(() => import('src/pages/admin-afiliados')),
		layout: 'VerticalLayout',
		roles: ['admin'],
	},
	{
		path: '/cadastro',
		component: lazy(() => import('src/pages/admin-aprovar-cadastro')),
		layout: 'VerticalLayout',
		roles: ['admin'],
	},
	{
		path: '/saques',
		component: lazy(() => import('src/pages/admin-aprovar-saque')),
		layout: 'VerticalLayout',
		roles: ['admin'],
	},
	{
		path: '/profile-admin',
		component: lazy(() => import('src/pages/admin-perfil')),
		layout: 'VerticalLayout',
		roles: ['admin'],
	},
	{
		path: '/contestation-admin',
		component: lazy(() => import('src/pages/admin-contestacao')),
		layout: 'VerticalLayout',
		roles: ['admin'],
	},

	/**
	 * Affiliated
	 */

	{
		path: '/profile',
		component: lazy(() => import('src/pages/user-perfil')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/rank',
		component: lazy(() => import('src/pages/user-rank')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/gameficacao',
		component: lazy(() => import('src/pages/user-gamificacao')),
		layout: 'FullLayout',
		roles: ['affiliated'],
	},
	{
		path: '/contestations',
		component: lazy(() => import('src/pages/user-contestacao')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/analises',
		component: lazy(() => import('src/pages/user-analises')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/links-personalizados',
		component: lazy(() => import('src/pages/user-links')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/desempenho',
		component: lazy(() => import('src/pages/user-desempenho')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/carteira',
		component: lazy(() => import('src/pages/user-comissao-saque')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/carteiras-cadastradas',
		component: lazy(() => import('src/pages/user-carteira')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/gerenciamento',
		component: lazy(() => import('src/pages/user-sub-afiliados')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/termos',
		component: lazy(() => import('src/pages/termos')),
		layout: 'VerticalLayout',
		roles: ['affiliated'],
	},
	{
		path: '/login',
		component: lazy(() => import('src/pages/authentication/login')),
		layout: 'FullLayout',
		roles: ['guest'],
	},
	{
		path: '/recover-password',
		component: lazy(
			() => import('src/pages/authentication/recover-password')
		),
		layout: 'FullLayout',
		roles: ['guest'],
	},
	{
		path: '/register',
		component: lazy(() => import('src/pages/authentication/register')),
		layout: 'FullLayout',
		roles: ['guest'],
	},
	{
		path: '/reset-password',
		component: lazy(
			() => import('src/pages/authentication/reset-password')
		),
		layout: 'FullLayout',
		roles: ['guest'],
	},
	{
		path: '/reset-password-success',
		component: lazy(() => import('src/pages/authentication/success')),
		layout: 'FullLayout',
		roles: ['guest'],
	},
	{
		path: '/await',
		component: lazy(() => import('src/pages/authentication/await')),
		layout: 'FullLayout',
		roles: ['guest'],
	},
];

export default PagesRoutes;
