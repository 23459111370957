import { Link, useLocation } from 'react-router-dom';

import { MouseEventHandler } from 'react';
import logo from 'src/assets/logo.png';
import logoArbety from 'src/assets/logo-arbety-menu.png';

interface MenuLogoProps {
	onClose?: MouseEventHandler<HTMLAnchorElement>;
}

export default function MenuLogo(props: MenuLogoProps) {
	const location = useLocation();
	const logoSrc = location.pathname === '/rank' ? logoArbety : logo;

	return (
		<div className="w-full justify-center items-centerr">
			<Link
				to="/analises"
				onClick={props.onClose}
				className="w-full justify-center items-center"
			>
				<img
					className={`${
						location.pathname === '/rank' ? 'h-[50px]' : 'h-[75px]'
					} `}
					src={logoSrc}
					alt="logo"
				/>
			</Link>
		</div>
	);
}
