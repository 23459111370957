import { Modal } from 'antd';

interface TermosModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export const TermosModal = ({ open, setOpen }: TermosModalProps) => {
	return (
		<Modal
			centered
			open={open}
			onOk={() => setOpen(false)}
			onCancel={() => setOpen(false)}
			width={1000}
			style={{
				height: '100%',
				maxHeight: '80vh',
				overflowY: 'auto',
			}}
			okButtonProps={{
				style: {
					border: 'none',
					background:
						'linear-gradient(90deg, #0010f7 0%, #1be7ff 100%)',
				},
			}}
			okText="Aceitar"
			cancelButtonProps={{ style: { display: 'none' } }}
		>
			<div className="relative">
				<button
					onClick={() => setOpen(false)}
					className="absolute top-0 right-0 text-white text-2xl font-bold rounded-full w-8 h-8 flex items-center justify-center"
					style={{
						background:
							'linear-gradient(90deg, #0010f7 0%, #1be7ff 100%)',
					}}
				>
					&times;
				</button>
				<header className="py-10">
					<div className="text-white text-center">
						<h1 className="text-2xl font-bold">
							Bem-vindo à plataforma de afiliados da EuroWin Bet
						</h1>
					</div>
				</header>

				<section className="bg-white p-6 rounded-lg">
					<h2 className="text-2xl font-semibold mb-4">
						Termos de Uso
					</h2>

					<p className="mb-4">
						Estes termos de uso estabelecem os direitos e
						responsabilidades ao utilizar nossa plataforma. Ao se
						registrar e utilizar nossos serviços, você concorda em
						cumprir estes termos. Recomendamos que você leia
						atentamente todas as cláusulas apresentadas abaixo.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Aceitação dos Termos
					</h3>
					<p className="mb-4">
						1.1 Ao acessar ou usar a plataforma de afiliados, você
						concorda com estes termos de uso e confirma sua
						aceitação todas as cláusulas se referem a todos os
						domínios e subdomínios da Eurowin.bet
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Registro
					</h3>
					<p className="mb-4">
						2.1 Para se tornar um afiliado, você deve se registrar
						em nossa plataforma de acordo com as instruções
						fornecidas. O registro está sujeito à nossa aprovação e
						reserva-se o direito de recusar o registro sem fornecer
						justificativa. 2.2 Você deve fornecer informações
						precisas e atualizadas durante o processo de registro. É
						de sua responsabilidade manter seus dados atualizados.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Fonte de Tráfego
					</h3>
					<p className="mb-4">
						3.1 É necessário ter uma fonte de tráfego limpa e
						confiável para promover a Casa de Apostas. Caso
						identifiquemos uma fonte de tráfego influenciada ou não
						autorizada, reservamo-nos o direito de banir sua conta
						instantaneamente, sem a possibilidade de receber os
						fundos remanescentes. 3.2 Você concorda em utilizar
						apenas métodos de marketing e promoção autorizados pela
						Casa de Apostas. Quaisquer atividades de marketing
						fraudulentas, enganosas ou não autorizadas resultarão na
						rescisão imediata da sua conta.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Promessas do Afiliado
					</h3>
					<p className="mb-4">
						4.1 Toda promessa feita pelo afiliado é de sua inteira
						responsabilidade. Você deve garantir que todas as
						informações fornecidas sobre a Casa de Apostas sejam
						precisas, atualizadas e não enganosas. 4.2 Você concorda
						em não fazer promessas enganosas ou não autorizadas em
						nome da Casa de Apostas, nem fornecer informações falsas
						sobre os produtos, serviços ou promoções oferecidos.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Contas Promocionais
					</h3>
					<p className="mb-4">
						5.1 Em alguns casos, a Casa de Apostas pode fornecer
						saldo promocional em contas de afiliados. Essas contas
						promocionais não serão elegíveis para saque, mesmo que
						tenham sido feitos depósitos com saldo real. O saldo
						promocional fornecido é exclusivamente para fins
						promocionais e não pode ser retirado.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Força Maior
					</h3>
					<p className="mb-4">
						6.1 Não seremos responsabilizados por qualquer falha ou
						atraso no fornecimento do serviço devido a um evento de
						Força Maior que possa ser razoavelmente considerado fora
						de nosso controle. Isso inclui casos fortuitos, doenças
						pandêmicas, disputas comerciais ou trabalhistas, corte
						de energia, ato, falha ou omissão de qualquer governo,
						ou autoridade, obstrução ou falha de serviços de
						telecomunicações, ou qualquer outro atraso, ou falha
						causada por terceiros. Nesses casos, reservamo-nos o
						direito de cancelar ou suspender o serviço sem incorrer
						em qualquer responsabilidade.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Sanções e Fraudes
					</h3>
					<p className="mb-4">
						7.1 Buscaremos sanções criminais e contratuais contra
						qualquer afiliado envolvido em fraude, desonestidade ou
						atos criminosos. Reservamo-nos o direito de reter o
						pagamento de comissões a qualquer afiliado onde houver
						suspeita de qualquer um desses comportamentos.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Propriedade Intelectual
					</h3>
					<p className="mb-4">
						8.1 Todos os direitos de propriedade intelectual
						relacionados à marca, logotipos, materiais de marketing
						e qualquer outro conteúdo fornecido pela Casa de Apostas
						permanecem exclusivamente conosco. O afiliado concorda
						em não utilizar, reproduzir, modificar ou distribuir
						esses materiais sem autorização prévia por escrito.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Comissões e Recompensas
					</h3>
					<p className="mb-4">
						9.1 Quando um afiliado compartilha seu link de
						referência com amigos, familiares ou através de
						anúncios, e um jogador se inscreve no site da Casa de
						Apostas por meio desse link, esse jogador se torna uma
						referência do afiliado. O afiliado será elegível para
						receber comissões e recompensas extras com base na
						atividade de jogo desse jogador referido.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Alterações nos Termos de Uso
					</h3>
					<p className="mb-4">
						10.1 Reservamo-nos o direito de alterar estes termos de
						uso a qualquer momento, mediante aviso prévio razoável.
						Recomendamos que você revise periodicamente os termos
						atualizados. O uso contínuo da plataforma após a
						modificação dos termos será considerado como sua
						aceitação das alterações.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Rescisão
					</h3>
					<p className="mb-4">
						11.1 Podemos rescindir ou suspender sua conta de
						afiliado a qualquer momento, por qualquer motivo, com ou
						sem aviso. 11.2 Você pode rescindir sua conta de
						afiliado a qualquer momento, enviando um aviso por
						escrito para nós.
					</p>

					<h3 className="text-xl font-semibold mt-6 mb-2">
						Disposições Gerais
					</h3>
					<p className="mb-4">
						12.1 Estes termos de uso constituem o acordo integral
						entre você e a Casa de Apostas em relação ao uso da
						plataforma de afiliados. 12.2 Se qualquer disposição
						destes termos for considerada inválida ou inexequível,
						as demais disposições permanecerão em pleno vigor e
						efeito. 12.3 A falha em exercer ou fazer valer qualquer
						direito, ou disposição destes termos de uso não
						constituirá uma renúncia a tal direito ou disposição.
					</p>
				</section>
			</div>

			<footer className="py-4">
				<div className="mx-auto mt-4 text-blue-500 text-center">
					<p className="hp-badge-text text-[15px] hp-font-weight-600 hp-mb-0 hp-text-color-dark-30">
						© 2023 Eurowin | Todos os direitos reservados.
					</p>
				</div>
			</footer>
		</Modal>
	);
};
