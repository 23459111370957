import { Layout } from 'antd';
import { TermosModal } from './TermosModal';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export default function MenuFooter() {
	const { Footer } = Layout;
	const [open, setOpen] = useState(false);

	const location = useLocation();

	return (
		<Footer
			className={` ${
				location.pathname === '/rank'
					? 'bg-[#222222]'
					: 'hp-bg-color-black-20 hp-bg-color-dark-90'
			} `}
		>
			<div className="w-full flex justify-between">
				<p
					onClick={() => setOpen(true)}
					className="hp-badge-text text-[15px] cursor-pointer hp-font-weight-600 hp-mb-0 hp-text-color-dark-30"
				>
					© 2023 Eurowin | Todos os direitos reservados.
				</p>
				<p
					onClick={() => setOpen(true)}
					className="hp-badge-text text-[15px] cursor-pointer hp-font-weight-600 hp-mb-0 hp-text-color-dark-30"
				>
					Termos e condições de uso
				</p>
			</div>

			<TermosModal open={open} setOpen={setOpen} />
		</Footer>
	);
}
