import { Modal, message } from 'antd';
import { CloseCircle, Warning2 } from 'iconsax-react';
import { useEffect, useState } from 'react';
import LoadingComponent from 'src/components/loading';
import useAuth from 'src/contexts/AuthContext';
import useData from 'src/contexts/DataContext';
import { formatCurrency } from 'src/utils/numbers';
import '../styles/carteira.css';

const ModalSaque = () => {
	const { userData } = useAuth();
	const {
		solicitarPagamento,
		fetchPartnerWallet,
		visibleModalSaque,
		closeModalSaque,
	} = useData();

	const [valueSaldo, setValueSaldo] = useState('');
	const [clickModalLoading, setClickModalLoading] = useState(false);
	const [valueCarteira, setValueCarteira] = useState('');
	const [walletData, setWalletData] = useState<any>(null);

	const balance =
		(userData?.balance_cpa ?? 0) + (userData?.balance_revenue_share ?? 0);
	const formattedBalance = formatCurrency(balance);

	useEffect(() => {
		if (visibleModalSaque === true) {
			const fetchData = async () => {
				try {
					const response = await fetchPartnerWallet();

					setWalletData(response);
				} catch (err) {
					console.error(err);
				}
			};
			fetchData();
		}
	}, [visibleModalSaque, fetchPartnerWallet]);

	const SaqueClick = async () => {
		try {
			await solicitarPagamento(valueSaldo, valueCarteira);
			setClickModalLoading(false);
			setValueSaldo('');
			setValueCarteira('');
			closeModalSaque();
			message.success('Saque efetuado com sucesso.', 2);
		} catch {
			setClickModalLoading(false);
			setValueSaldo('');
			setValueCarteira('');
			closeModalSaque();
		}
	};

	const isSaqueDisabled =
		clickModalLoading ||
		!valueSaldo ||
		!valueCarteira ||
		Number(valueSaldo) < 200;

	return (
		<Modal
			visible={visibleModalSaque}
			onCancel={closeModalSaque}
			footer={null}
			wrapClassName="custom-modal"
		>
			<div className="custom-modal2">
				{clickModalLoading && (
					<div className="w-full h-full bg-white absolute z-50">
						<LoadingComponent />
					</div>
				)}

				<CloseCircle
					onClick={closeModalSaque}
					className="icon-close-carteira"
					size="30"
				/>

				<div className="header-modal">
					<div className="header-items">
						<div className="title-modal">Solicitar Saque</div>
					</div>
					<p className="flex gap-2">
						<Warning2 size="22" color="#00FF66" />
						Solicitacao de pagamento ate quarta feira 12h, apos esse
						horario so ira ser aprovado na outra semana.
					</p>
				</div>
				<div className="content-modal">
					<div className="label">
						Valor
						<input
							value={valueSaldo}
							onChange={(event) => {
								setValueSaldo(
									event.target.value.replace(/[^\d]/g, '')
								);
							}}
							type="number"
						/>
						<span className="alert-saldo">
							Saldo disponível na conta:{' '}
							<span>{formattedBalance}</span>
						</span>
						{Number(valueSaldo) < 200 && (
							<span className="text-red-700">
								Valor mínimo é R$200,00
							</span>
						)}
					</div>

					<div className="label">
						Carteira
						<select
							onChange={(ev) => setValueCarteira(ev.target.value)}
							value={valueCarteira}
						>
							<option value="">Selecione sua carteira</option>
							{walletData?.map((section: any, pos: any) => (
								<option key={pos} value={section.id}>
									{section.name}
								</option>
							))}
						</select>
						{isSaqueDisabled && valueCarteira === '' && (
							<p className="text-red-700">
								Por favor, selecione a carteira.
							</p>
						)}
					</div>

					<button
						onClick={() => {
							if (!isSaqueDisabled) {
								setClickModalLoading(true);
								SaqueClick();
							}
						}}
						disabled={isSaqueDisabled}
					>
						Solicitar saque
					</button>
				</div>
			</div>
		</Modal>
	);
};
export default ModalSaque;
